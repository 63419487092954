import { green } from "@mui/material/colors";
import React, { useState } from "react";
import '../css/Gallery.css'
import CloseIcon from '@mui/icons-material/Close';


import img1 from '../images/4a.jpg';
import img2 from '../images/3a.jpg';
import img3 from '../images/2a.jpg';
import img4 from '../images/5a.jpg';


const Gallery = () => {
    let data = [
        {
            id: 1,
            imgSrc: img1,
        },
        {
            id: 2,
            imgSrc: img2,
        },
        {
            id: 3,
            imgSrc: img3,
        },
        {
            id: 4,
            imgSrc: img4,
        },
        
       
       
    ]
    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempImgSrc] = useState('');
    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    }


    return (
        <>
                
            <section id="gallery" className="text-center container mt-5 mb-5">
               <h1 className="mb-2" style={{ color: green[500] }}>Galería</h1> 
                <div className={model ? "model open" : "model"}>
                    <img src={tempimgSrc} />
                    <CloseIcon onClick={() => setModel(false)} />
                </div>
                <div className="gallery">
                    {data.map((item, index) => {
                        return (
                            <div className="pics bg-image hover-overlay ripple shadow-1-strong rounded"
                                data-ripple-color="light" key={index} onClick={() => getImg(item.imgSrc)}>
                                <img src={item.imgSrc} style={{ width: '100%' }} />
                            </div>
                        )
                    })}
                </div>
            </section>




        </>

    )
}

export default Gallery;