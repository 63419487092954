import restaurant from "./images/moldova.jpg";
import biserica from "./images/biserica2.jpg";
import img_card from "./images/1a.jpg";
import imgheadermb from "./images/f21m.jpg";
import imgheader from "./images/f2m.jpg";
import imgheadermiini from "./images/f4.jpg";
import imgheadermiinimb from "./images/f4.jpg";
import logo from './images/stea.png'



const data = {
    introData: [{
        copilul: "Adam",
        familia: "Adam",
        logo: logo,
        tata: "Victor",
        mama: "Olga",
        data: "22 Octombrie 2022",
        data_confirmare: "5 octombrie 2022",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "victorraboda@gmail.com", 
       tel: "+44 7450 119222",
       phone: "tel:+447450119222",
       viber: "viber://chat?number=%2B447450119222",
       whatsapp: "https://wa.me/+447450119222",
       messenger: "https://www.messenger.com/t/olga.vacari",
       tel1: "+44 7941 477988",
       phone1: "tel:+447941477988",
       viber1: "viber://chat?number=%2B447941477988",
       whatsapp1: "https://wa.me/+447941477988",
       messenger1: "https://www.messenger.com/t/100006962147865",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica",
            name: "Serbian orthodox Church of The Holy Prince Lazar",
            data: "22 octombrie 2022, sâmbătă, ora 12:30",
            adress: "92 Griffins Brook Ln, Birmingham B30 1QG",
            harta: "https://goo.gl/maps/rbCaY4B2UykV3y3T9",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Moldova Venue ",
            data: "22 octombrie 2022, sâmbătă, ora 18:00",
            adress: "76-92 Great Russell st, Northampton NN1 3BU",
            harta: "https://goo.gl/maps/vtB1VfqmvZqcCP2W7",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;